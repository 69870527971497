<script lang="ts" setup>
import { CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import KeyInfo from '~/assets/icons/key-info.svg'
import Collaterals from '~/assets/icons/collaterals.svg'
import Scoring from '~/assets/icons/scoring.svg'
import InvestOnline from '~/assets/icons/invest-online.svg'

type Benefit = {
  icon: string
  text: string
}

const { t } = useI18n()

const benefits: Benefit[] = [
  { icon: KeyInfo, text: t('benefits.keyInfo') },
  { icon: Scoring, text: t('benefits.scoring') },
  { icon: Collaterals, text: t('benefits.collaterals') },
  { icon: InvestOnline, text: t('benefits.investOnline') },
]
</script>

<template>
  <div class="">
    <CfgTypography
      class="mb-16 md:text-center lg:text-left"
      :size="CfgTypographySizes.h2"
      tag-name="h2"
    >
      {{ $t('homepage.benefits.title2') }}
    </CfgTypography>

    <ul class="benefits_list grid auto-rows-fr gap-7 sm:max-w-[600px] md:mx-auto md:grid-cols-2 lg:max-w-full lg:grid-cols-4 lg:gap-5 xl:gap-7">
      <li
        v-for="(benefit, index) in benefits"
        :key="index"
        class="benefits_list__item col-span-1 flex sm:justify-center"
      >
        <div class="benefit-item flex w-full items-center gap-x-5 gap-y-4 sm:items-center lg:w-max">
          <div class="benefit-item__icon pointer-events-none flex size-[70px] shrink-0 select-none items-center justify-center rounded-xl">
            <img :alt="benefit.text" :src="benefit.icon">
          </div>

          <CfgTypography
            class="shrink-1 grow leading-tight sm:text-left md:text-center lg:text-left"
            :html="benefit.text"
            :size="CfgTypographySizes.md"
            tag-name="p"
          />
        </div>
      </li>
    </ul>
  </div>
</template>
